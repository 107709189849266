var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLDETAIL" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          !_vm.apprMode
                            ? _c("c-appr-btn", {
                                ref: "appr-btn",
                                attrs: {
                                  name: "internal-action-appr-btn",
                                  editable: _vm.editable,
                                  approvalInfo: _vm.approvalInfo,
                                },
                                on: {
                                  beforeApprAction: _vm.approvalAction,
                                  callbackApprAction:
                                    _vm.approvalActionCallback,
                                  requestAfterAction: _vm.research,
                                },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.isOld &&
                          !_vm.apprMode
                            ? _c("c-btn", {
                                attrs: { label: "LBLREMOVE", icon: "delete" },
                                on: { btnClicked: _vm.deleteAction },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  isSubmit: _vm.isSave,
                                  url: _vm.saveUrl,
                                  param: _vm.action,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveAction,
                                  btnCallback: _vm.saveActionCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBL0010105",
                            name: "actionName",
                          },
                          model: {
                            value: _vm.action.actionName,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "actionName", $$v)
                            },
                            expression: "action.actionName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            range: true,
                            label: "LBLPERIOD",
                            name: "period",
                          },
                          model: {
                            value: _vm.action.period,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "period", $$v)
                            },
                            expression: "action.period",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "LBLREVIEWER",
                            name: "reviewUserId",
                          },
                          model: {
                            value: _vm.action.reviewUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "reviewUserId", $$v)
                            },
                            expression: "action.reviewUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "LBLAPPROVER",
                            name: "approvalUserId",
                          },
                          model: {
                            value: _vm.action.approvalUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "approvalUserId", $$v)
                            },
                            expression: "action.approvalUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.action.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "plantCd", $$v)
                            },
                            expression: "action.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-1 col-xl-1",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            codeGroupCd: "SAI_INTERNAL_ACTION_TYPE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "saiInternalActionTypeCd",
                            label: "LBL0010117",
                          },
                          model: {
                            value: _vm.action.saiInternalActionTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.action,
                                "saiInternalActionTypeCd",
                                $$v
                              )
                            },
                            expression: "action.saiInternalActionTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 3,
                            label: "LBL0010121",
                            name: "auditPurposeScope",
                          },
                          model: {
                            value: _vm.action.auditPurposeScope,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "auditPurposeScope", $$v)
                            },
                            expression: "action.auditPurposeScope",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 3,
                            label: "LBL0010122",
                            name: "auditPreRequire",
                          },
                          model: {
                            value: _vm.action.auditPreRequire,
                            callback: function ($$v) {
                              _vm.$set(_vm.action, "auditPreRequire", $$v)
                            },
                            expression: "action.auditPreRequire",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "c-table",
        {
          ref: "actionTable",
          attrs: {
            title: "LBL0010123",
            columns: _vm.grid.columns,
            data: _vm.action.actionPlans,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "saiInternalActionPlanId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "userIds"
                    ? [
                        _c("c-tag", {
                          attrs: {
                            editable: _vm.editable,
                            icon: "person_outline",
                            itemText: "userName",
                            itemValue: "check",
                            name: "userIds",
                            isAdd: !_vm.disabled,
                            disabled: _vm.disabled,
                          },
                          on: {
                            addTag: function ($event) {
                              return _vm.addManager(props.row)
                            },
                            removeTag: (item) =>
                              _vm.removeManager(props.row, item),
                          },
                          model: {
                            value: props.row.userIds,
                            callback: function ($$v) {
                              _vm.$set(props.row, "userIds", $$v)
                            },
                            expression: "props.row.userIds",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "피심사팀 제외", icon: "remove" },
                        on: { btnClicked: _vm.deletePlan },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "피심사팀 추가", icon: "add" },
                        on: { btnClicked: _vm.addPlan },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }